@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "src/styles/mixins";

:root {
  --text-primary: #0D0D27;
  --text-gray-primary: #A1AAB6;
  --text-gray-secondary: #0A112D80;
  --text-gray-tertiary: #686C7E;

  --black: #0D0D27;
  --blue: #5954FF;
  --blue-secondary: #7BBAF4;
  --dark-blue: #27257B;
  --dark-blue-secondary: #6A8BFD;
  --light-blue: #EBEBF6;
  --red: #FD7B7A;
  --green: #A2DA5C;
  --pink: #FD7FD2;
  --purple: #937BF3;
  --orange: #F6B466;
  --white: #FFFFFF;
  --gray-primary: #EFF2F6;
  --gray-secondary: #FAFAFB;
  --gray-blue: #DFE3EB;
  --gray-blue-secondary: #FAFBFD;

  --input-bg: #FAFAFB;
  --input-border: #DFE3EB;
  --input-focus-border: #686C7E;
  --input-disabled-border: #F1F1F1;

  --blue-hover: #4844E2;
  --blue-active: #4440C8;
  --blue-focus: #3432A1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

svg, img {
  display: block;
}

html {
  font-size: 16px;

  @media (max-width: 1220px) {
    font-size: 16 / 1220 * 100vw;
  }

  @media (max-width: 768px) {
    font-size: 16 / 768 * 100vw;
  }

  @media (max-width: 480px) {
    font-size: 16 / 320 * 100vw;
  }
}

html, body {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;

  &:has(.modal.active) {
    overflow: hidden;
  }
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.empty-capt {
  font-size: rem(24px);
}

table {
  min-width: 400px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;

  thead {
    background-color: var(--gray-secondary);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #686C7E;

    th, td {
      padding: 20px 16px;
    }
  }

  tbody {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0D0D27;

    tr {
      border-bottom: 1px solid #DFE3EB;

      &:hover {
        background-color: #f3f3f3;
      }

      &:last-of-type {
        border: none;
      }

      th, td {
        white-space: nowrap;
        padding: 16px;
      }
    }
  }
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  column-gap: 16px;
  list-style: none;

  li {
    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;

    &.slick-active {
      button {
        background-color: #5954FF;
      }
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 12px;
      height: 12px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      border-radius: 50%;
      outline: none;
      background-color: #DFE3EB;

      transition: background-color .2s;
    }
  }
}

.react-datepicker {
  font-family: "Inter", sans-serif;

  border: 1px solid var(--gray-blue);

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: var(--input-bg);
    border-bottom: 1px solid var(--gray-blue);
  }

  .react-datepicker__day--selected {
    background-color: var(--blue);
    border-radius: rem(8px);
  }
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  top: 8px;
  height: 6px;
  width: 6px;
  background-color: #F4F6F9;
}

.react-datepicker__day--keyboard-selected {
  background-color: #F4F6F9;
  color: var(--text-gray-tertiary);

  &:hover {
    background-color: var(--blue);
    color: var(--white);
  }
}