@import "src/styles/mixins";

.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: rem(36px);
    height: rem(36px);
    animation: spin .8s linear infinite forwards;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}